<template>
    <div id="main-content" class="mobile-container">
        <h3 class="box has-background-warning has-text-white has-text-weight-semibold is-capitalized p-2 mb-5">Semua transaksi pembayaran hanya  melalui transfer ke BCA no.rek 8205136360</h3>
        <div v-for="order in orders" :key="order.id" class="card order-card mb-5">
            <header class="card-header">
                <p class="card-header-title has-text-primary is-capitalized">{{order.layanan_dipilih}}</p>
            </header>
            <div class="card-content">
                <div class="columns is-mobile mb-0">
                    <div class="column is-one-third">
                        <!-- <span class="img-icon"><img src="/img/invoice.png" alt=""></span> -->
                        No. order
                    </div>
                    <div class="column">: {{order.kode_voucher}}</div>
                </div>
                <div class="columns is-mobile mb-0">
                    <div class="column is-one-third">
                        Waktu janji
                    </div>
                    <div class="column">: {{formatDate(order.tanggal, 'dd-MM-yyyy HH:mm')}}</div>
                </div>
                <div class="columns is-mobile mb-0">
                    <div class="column is-one-third">
                        Lokasi
                    </div>
                    <div class="column is-capitalized">: {{order.teks_koordinat}}</div>
                </div>
                <div v-if="order.detail_alamat !== null && order.detail_alamat !== ''" class="columns is-mobile mb-0">
                    <div class="column is-one-third">
                        Catatan Lokasi
                    </div>
                    <div class="column is-capitalized">: {{order.detail_alamat}}</div>
                </div>
                <div class="columns is-mobile mb-0">
                    <div class="column is-one-third">
                        Status
                    </div>
                    <div class="column is-capitalized" :class="{
                        'has-text-danger': order.status === 'pengajuan survey ditolak' || order.status === 'dibatalkan',
                        'has-text-warning': order.status === 'sedang disurvey',
                        'has-text-primary': order.status === 'pengerjaan',
                        'has-text-success': order.status === 'selesai' }">: {{order.status}}</div>
                </div>
                <div v-if="order.catatan !== null && order.catatan !== ''" class="columns is-mobile mb-0">
                    <div class="column is-one-third">
                        Catatan
                    </div>
                    <div class="column is-capitalized">: {{order.catatan}}</div>
                </div>
            </div>
        </div>
        <p v-if="orders.length === 0 && !isLoading" style="text-align: center;">Belum ada data</p>
        <p v-if="isLoading" style="text-align: center;">Memuat data</p>
    </div>
</template>

<script>
import { format } from 'date-fns'
import { serializeErrorMessage } from '@/_helpers'

export default {
    data () {
        return {
            isLoading: true,
            user: {
                id: null,
                nama: null,
                no_wa: null
            },
            orders: []
        }
    },
    mounted() {
        if (localStorage.getItem('myaccount') !== null && localStorage.getItem('myaccount') !== '') {
            const myaccount = JSON.parse(localStorage.getItem('myaccount'))
            this.user.id = myaccount.id
            this.user.nama = myaccount.name
            this.user.no_wa = myaccount.phone_number
            // this.form.titik_koordinat = myaccount.address.titik_koordinat
            // this.form.teks_koordinat = myaccount.address.teks_koordinat
            // this.form.detail_alamat = myaccount.address.detail_alamat
            this.getOrders()
        }
    },
    methods: {
        getOrders () {
            this.isLoading = true
            fetch(process.env.VUE_APP_BASE_URL + 'api/get-orders?user_id=' + this.user.id).then(response => response.json())
            .then(data => {
                this.isLoading = false
                this.orders = data
            }).catch(error => {
                this.isLoading = false
                this.$notify({ type: 'error', text: serializeErrorMessage(error)})
            })
        },
        formatDate (string, formatText) {
            return format(new Date(string), formatText)
        }
    }
}
</script>
